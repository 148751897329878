@import './colors.scss';

.title--purple,
.text--purple {
  color: $darkPurple !important;

  line-height: 1.2;
}
.title--purple {
  font-size: 30px;
}
.text--purple {
  font-size: 18px;
}
.text--turqoise {
  color: $turqoise;
}
.text--secondary,
a.text--secondary {
  color: rgb(208, 206, 206);
}
.text__white {
  color: white !important;
}
.text__yellow {
  color: $yellow !important;
}
.purple {
  color: $darkPurple;
}
.bold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

h1, h2, h3 {
  font-family: "Roboto-Bold";
}
