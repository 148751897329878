@import './colors.scss';

.isi {
  background-color: white;
  box-shadow: inset 0px 11px 8px -10px rgba(0, 0, 0, 0.5),
    inset 0px -11px 8px -10px rgba(0, 0, 0, 0.5);
  margin-top: 2px;
  color: #00597d;
  padding: 20px 50px;
  tr {
    position: relative;
    display: table;
  }
  table tr td:first-child:before {
    content: '';
    position: absolute;
    width: 3.5px;
    height: 3.5px;
    border-radius: 50%;
    left: 0px;
    top: 8px;
    background: #00597d;
  }

  .isi-wrapper {
    color: #00597d !important;
    .isi-preview {
      color: #00597d !important;
      // .text a,
      // a {
      //   color: #00597d;
      // }
      overflow: hidden;
      transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
      background: white;
      color: black;
      cursor: pointer;
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translate3d(-50%, 0%, 0);
      z-index: 2147483647 !important;
      width: calc(100% - 40px);
      padding: 10px;
      font-size: 12px !important;
      // box-shadow: 0 0 10px;
      background: linear-gradient(#fafafa, #f9fafa, #ebedee);
      width: 100%;
      .text {
        font-size: 12px;
      }
    }
    .isi-higher-preview {
      bottom: 120px;
    }
  }
}

.uncollapsed.isi-preview {
  // overflow-y: auto;
  transform: translate3d(-50%, 0%, 0) !important;
}

.isi-wrapper .hide.isi-preview {
  transform: translate3d(-50%, 100%, 0);
}

.text {
  font-size: 16px;

}
.header{
  font-size: 18px;
}


.isi-arrow {
  color: black;
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 15px;
  cursor: pointer;
  z-index: 9999999999999999;
  transform: rotate(180deg);
}

div.isi-wrapper > div.text > * {
  line-height: 16px;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .isi-wrapper {
    padding: 20px;
  }
  .isi {
    padding: 20px;
  }
  .isi,
  .isi .text {
    font-size: 12px;
  }

  .isi-preview .text {
    padding-top: 26px;
    font-size: 12px;
  }
  .isi .isi-wrapper .isi-preview {
    max-height: 60%;
    z-index: 99999999999999999999999999999999999;
    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
  }

  .isi-preview::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
  .header{
    font-size: 14px;
  }
}

@media screen and (max-width: 1200px) {
  .isi .isi-wrapper .isi-preview {
    z-index: 999999999999999999999999999999999999;
  }
}
.isi-preview {
  z-index: 999999999999999999999999999999999999999999 !important;
}

.isi-bold {
  font-weight: 600;
}

.isi-ul {
  padding-left: 15px;
}
