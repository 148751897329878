.headerWarning {
  position: fixed;
  top: 0;
  background-color: #dfdee5;
  width: 100%;
  text-align: right;
  color: #04799e;
  font-weight: 800;
  padding-right: 20px
}

.sub-header {
  display: none;
  height: 35px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  li.ant-menu-item.ant-menu-item-only-child {
    margin: 0 !important;
  }
  li {
    margin: 0 10px !important;
  }
}
 ul { margin-left: auto;}
.logo {
  height: 90%;
}
.btn_solid--green {
  background: #00597d !important;
  color: white;
}



@media screen and (max-width: 1200px) {
  .ant-layout-header {
    padding: 5px !important;
  }

  .sub-header {
    display: flex;
    position: fixed;
    top: 64px;
    left: 0;
    width: 100%;
    background: white;

    ul {
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: center;

      padding: 0;
      li {
        display: flex;
        align-items: center;
        line-height: 1;
        margin: 0;
      }
    }
  }
  .header .hide-desktop,
  .logout-button {
    display: none !important;
  }

}

@media screen and (max-width: 750px) {
  .header-disclaimer {
    font-size: 10px;
    line-height: 13px;
    text-align: center;
  }

  .header-pi {
    a { font-size: 10px !important; }
  }
}
.ant-layout-header{
  justify-content: space-between;
}

@media screen and (max-width: 1000px) {
  img.logo {
    height: 40px;
  }
  .ant-layout-header{
    justify-content: center;
  }

}

@media screen and (max-width: 585px) {
  .btn__outline--purple,
  .btn__solid--purple,
  .btn__outline--green,
  .btn__solid--green {
    font-size: 8px !important;
    padding: 3px 5px !important;
  }

  .ant-layout-header::after {
    width: 100px !important;
  }
}

.btn__outline--purple {
  border: 2px solid #624b78;
  border-radius: 6px;
  align-self: center;
  padding: 6px 10px;
}

.btn__outline--green {
  border: 2px solid #04799e;
  border-radius: 6px;
  align-self: center;
  padding: 6px 10px;
  color: #04799e;

  &:hover {
    background-color: #04799e;
    color: white;
  }
}
.btn__solid--green {
  border: 2px solid #04799e;
  background: #04799e;
  color: white !important;
  border-radius: 6px;
  align-self: center;
  padding: 6px 10px;
  color: #04799e;

  &:hover {
    background-color: white;
    color: #04799e !important;
  }
}
.btn__solid--purple {
  border: 2px solid #624b78;
  background-color: #624b78;

  border-radius: 6px;
  padding: 6px 10px;
  color: white;

  &:hover {
    -webkit-text-fill-color: white;
  }
}

li.ant-menu-item {
  margin: 0 !important;
  padding: 0 !important;
}

li.ant-menu-item {
  margin: 0 !important;
}
