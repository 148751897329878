.my-sessions {
  h1,
  h4,
  .title--white,
  .text--white {
    margin: 0 !important;
    color: white;
    text-align: left;
  }
}
.wrapper {
  max-width: 800px;
  margin: 0 auto;
  text-align: left !important;
  width: 100%;
}
.session-card {
  margin-bottom: 50px;
  max-width: 800px !important;
}
.bg-layer {
  min-height: 275px;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  width: 100%;
  z-index: -1;
  background-size: cover !important;

  &.primary {
    background: url('../img/hero-bg.png') no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
    position: absolute;
    top: 0;
    left: 0;
  }

  &.secondary {
    position: static;
    background: url('../img/jumbotron-green.jpg') no-repeat !important;
    background-size: cover !important;
  }
}

.session-avatar {
  display: none;
}

@media screen and (max-width: 768px) {
  .my-sessions {
    padding: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .ant-col {
    max-width: 600px;
  }
}
