.home-page {
  text-align: center;
  .session-card.ant-card {
    width: 100% !important;
    max-width: 100% !important;
  }

  h4 {
    text-transform: capitalize;
  }

  h1.title--white {
    font-size: 35px;
    margin: 20px 0;
  }
}

.bg-layer {
  min-height: 300px;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  width: 100%;
  z-index: -1;
  background-size: cover !important;

  &.primary {
    background: url('../img/hero-bg.png') no-repeat !important;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover !important;
    background-position: center center !important;
    min-height: 500px;
  }

  &.secondary {
    position: static;
    // background: url('../img/light-bg-section.svg') no-repeat !important;
    background-color: white;
    background-size: cover !important;
  }

  .third {
    background: url('../img/hero-bg.png');
    background-size: cover !important;
    &,
    h2,
    span {
      color: white;
    }
  }
}

.new_indications_bg {
  background: url('../img/New_indication_banner_BG.png');
}

.ant-row {
  flex-direction: row;
  flex-flow: row;
  min-width: 800px;

}

.ant-col {
  flex: 1;
}

.ant-body {
  height: 100% !important;
  max-height: 100% !important;
}

.upcoming-sessions {
  text-align: center !important;
  position: relative;

  .flex {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.vboothtext {
  text-align: left;
}
.vboothimg {
  max-width: 250px;
}

@media screen and (max-width: 1020px) {
  .vbooth {
    flex-direction: column;
  }
  .vboothimg {
    margin-top: 20px;
    align-self: center;
    max-width: 300px;
    width: 90%;
  }
  .vboothtext {
    text-align: center;
  }
  .vboothbutton div {
    margin: 15px auto 0 auto;
  }
}

@media screen and (min-width: 1200px) {
  .ant-col:nth-child(2) {
    margin-left: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .bg-layer {
    padding: 20px;
  }

  .home-page .ant-col {
    width: 100%;
    margin-left: auto !important;
    margin-bottom: 50px;
    display: flex;

    justify-content: center;
  }

  .upcoming-sessions {
    flex-direction: column;

    .ant-row {
      flex-direction: column;
      flex-flow: column;
      min-width: unset;

      .title--purple {
        padding-right: 0 !important;
      }
    }
  }

  .ant-card {
    width: 100%;
  }

  .home,
  .mobile-column {
    flex-direction: column-reverse !important;
    padding-left: 0;
    text-align: left;
    margin-bottom: 20px;

    // .ant-col {
    //   // width: 100%;
    //   // margin: 0 20px 50px auto;
    // }
    h2 {
      margin-top: 20px;
    }
  }
}

@media all and (-ms-high-contrast: none) {
  .ie-show-session {
    width: 100%;
  }

  /* IE10 */

  *::-ms-backdrop,
  .ie-show-session {
    width: 100%;
  }

  /* IE11 */
}
