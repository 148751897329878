@import './colors.scss';

.mkcInput {
  button {
    background-color: transparent !important;
    background: transparent !important;
    border: none;
    height: auto !important;
  }
  .labeled-checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #b8cdde;
    box-shadow: black 0px 1px 14px -6px;
    align-self: center;
  }

  label {
    font-size: 12px;
    color: white;

    text-align: left;
    align-items: center;
    position: relative;
    display: flex !important;
    justify-content: center;
    line-height: 1;
    a {
      letter-spacing: 0.5px !important;
    }
  }

  .checkbox-container {
    line-height: 1;
    // flex: auto;
    font-size: 10px;
    text-align: left;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    display: flex;
    align-items: center;
  }

  .go {
    // background: $turqoise;
    background: #b587b8;
    color: white !important;
    border-radius: 200%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    height: 25px;
    width: 25px;
    margin-left: auto;
    margin-right: -15px;
    &:hover {
      color: white;
    }
  }
  .checkbox-input + .checkbox-span {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    // overflow: hidden;
  }
  button {
    padding: 0;
  }
  .checkbox-input + .checkbox-span svg,
  svg {
    pointer-events: none;
    height: 50px !important;
    width: 50px !important;
    position: relative;
    top: 0;
    left: 0;
  }
  input[type='checkbox'] {
    outline: 2px solid lightgrey;
    background: transparent;
  }
  svg:not(:root) {
    overflow: visible !important;
  }
  // .checkbox-span {
  //   height: 88px !important;
  //   width: 88px !important;
  // }
  .checkbox-input {
    display: none;
    //   height: 88px;
    // width: 88px;
  }

  button,
  .ant-button,
  .ant-btn-primary,
  .ant-button-block {
    height: auto !important;
  }

  .border-button-wrapper button {
    height: auto !important;
  }
}
