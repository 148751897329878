.login-container {
  flex: auto;
  display: flex;
  height: 101vh;
  width: 100%;

  .ant-col {
    padding: 0 50px 50px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  @import './colors.scss';

  .login-bg {
    //background: url('../img/login-bg.png') no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    // background-position: right bottom !important;

    &.shrink {
      font-size: 25px;
    }
  }

  .login-bg {
    background-size: cover !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .title--purple {
    color: $darkPurple;
  }

  .login-row {
    width: 100%;
    height: calc(100vh - 64px - 48px); // minus header and footer
    overflow-y: hidden;
    display: flex;
  }

  .paragraph {
    color: white;
    font-size: 18px;
  }

  h1 {
    color: white !important;
    font-size: 3rem !important;
    font-family: 'Roboto-Bold';
    line-height: 1 !important;
  }

  .box {
    text-align: left;
    color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 99;

    h1,
    p {
      width: 100%;
      margin-bottom: 0;
      hyphens: none;
    }

    span {
      font-family: 'Roboto-Medium';
    }
  }
}

.formWrapper {
  width: 100%;
  text-align: left;
  position: absolute; // not compatible with IE
  height: 100%;
  padding: 50px;
}

@media all and (-ms-high-contrast: none) {
  .formWrapper {
    position: initial;
  }

  /* IE10 */

  *::-ms-backdrop,
  .formWrapper {
    position: initial;
  }

  /* IE11 */
}

@media screen and (max-width: 1000px) {
  .login-container,
  .login-row {
    flex-direction: column !important;
    flex-flow: column !important;
  }

  .formWrapper {
    position: static !important;
    height: auto !important;
    padding: 0;
  }

  .ant-col {
    //flex: none !important;
    //min-height: 50vh !important;
  }

  .box {
    width: 100% !important;
    text-align: center !important;
  }

  .login-form {
    margin: 20px auto !important;
  }
}

.login-form {
  .login-form-wrapper {
    width: 300px;
    text-align: center;

    h1 {
      color: white;
      font-weight: 300;
    }

    input {
      border-radius: 30px;
      border: 1px solid !important;
      padding: 15px;
      width: 100%;
      margin-bottom: 20px;
      background: white;
      color: black;
      border: none;
      outline: none;
      font-weight: 100;
    }
  }
}

.content,
.ant-layout-content {
  outline: none;
  display: flex;
  flex-direction: column !important;
  flex-flow: column !important;
  width: 100%;

  .site-layout-background,
  .site-layout-content {
    width: 100%;
    position: relative;
    z-index: 9999999999;
    flex: auto;
    display: flex;
    flex-flow: column !important;
  }
}

.fixed-width-1000 {
  max-width: 800px;
  margin: 0 auto;
}

.ant-row {
  width: 100%;
}

.beatTheBrainGame{
  margin-bottom: 50px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1200px) {
  .login-container {
    height: auto !important;
  }

  // .formWrapper {
  //   position: static;
  // }
}

@media screen and (max-width: 430px) {
  .box {
    h1 {
      font-size: 3rem !important;
    }
  }

  .ant-col {
    padding: 20px !important;
  }
}

@media screen and (max-width: 331px) {
  .box {
    h1 {
      font-size: 3rem !important;
    }
  }

  .ant-col {
    padding: 10px !important;
  }
}

@media screen and (max-width: 272px) {
  .box {
    h1 {
      font-size: 2.5rem !important;
    }
  }

  .ant-col {
    padding: 10px !important;
  }
}

.hide-scroll {
  // scrollbar-width: none;
  /* Firefox */
  // -ms-overflow-style: none;
  /* Internet Explorer 10+ */
}

.hide-scroll::-webkit-scrollbar {
  /* WebKit */
  // width: 0;
  // height: 0;
}

element.style {
  flex: 1 1 0%;
  position: relative;
  z-index: 0;
}
