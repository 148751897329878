.wrapper {
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
  padding: 50px;
}

.btn_solid--green {
  background: #00597d !important;
  color: white;
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding: 50px 20px;
  }
  .bg-layer {
    justify-content: center !important;
    align-items: center;
  }
}
.title--white {
  color: white !important;
  margin: 0 !important;
}

.bg-layer {
  min-height: 300px;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: -1;
  background-size: cover !important;
  background-position: center center !important;
  background: url('../img/hero-bg.png');
}
