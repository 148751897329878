@import './colors.scss';

.icons svg,
path {
  color: rgb(0, 89, 125) !important;
}
.session-card,
.SessionCard {
  color: $darkgrey;
  font-size: 12px;
  text-align: left;
  transition: all 0.3 cubic-bezier(0.31, 0.44, 0.445, 1.65);
  max-width: 800px;
  box-shadow: -5px -5px 30px 5px $lightblue, 5px 5px 30px 5px $darkPurple;
  display: flex;
  flex-direction: column;

  h2.title--purple {
    color: rgb(0, 89, 125) !important;
    font-family: 'Roboto-Bold' !important;
    padding-right: 50px;
    font-size: 36px;
    line-height: 1.2;
  }

  h2.title--green {
    color: $turqoise;
    font-family: 'Roboto-Bold' !important;
    padding-right: 50px;
    font-size: 36px;
    line-height: 1.2;
  }

  .ant-card-body {
    flex: auto !important;
    padding: 10px !important;
  }
  .sub {
    color: rgb(0, 89, 125) !important;
  }
  h5 {
    color: #797A7A !important;
  }
  &.small {
    h2 {
      font-size: 14px;
    }
  }

  .anticon {
    color: $darkPurple !important;
  }

  &.ant-card .ant-card-body {
    padding: 0 !important;
    font-size: 16px !important;
  }

  .ant-btn {
    svg {
      color: $darkPurple !important;
    }
  }

  .avatar-wrapper {
    padding: 0 20px;
    font-size: 0.8rem;
    line-height: 1.1;
  }
}

.alt-layout {
  max-width: 600px;

  h2.title--purple {
    font-size: 32px;
    padding: 0;
  }
}

.flex-column {
  flex-direction: column;
}

.session-card-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  text-align: right;

  .inline-flex {
    display: flex;
    margin-left: auto;
  }
}

.session-card-footer-share-btn span, .session-card-footer-register-btn span {
  font-family: 'Roboto-Bold';
}

@media screen and (max-width: 1200px) {
  // .session-card,
  .ant-col {
    flex: 1;
  }

  .session-card.alt-layout {
    // max-width: 500px;
    h2.title--purple {
      font-size: 25px;
    }
  }

  .avatar-wrapper {
    padding-left: 0;
    border-bottom: 1px solid lightgrey;
    padding: 0 0 30px 0 !important;
  }

  .mobile-column {
    flex-direction: column-reverse !important;
    padding-left: 0;
    text-align: left;
    margin-bottom: 20px;
    color: rgb(0, 89, 125) !important;

    h2 {
      margin-top: 20px;
    }
  }

  .column-reverse {
    flex-direction: column-reverse;

    .ant-col {
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 601px) {
  .session-card,
  .ant-row {
    flex-flow: column;
    flex-direction: column;
  }

  .ant-card {
    h2 {
      font-size: 20px;
    }

    .ant-typography {
      font-size: 12px;
    }

    .flex {
      flex-direction: column;
      flex-flow: column;
    }
  }
}

@media screen and (max-width: 601px) {
  .session-card-footer,
  .session-card-footer .inline-flex,
  .session-card-footer .horizontal-center {
    margin: 0 auto !important;
    flex-direction: column;
    width: 100%;
    max-width: 300px;

    button {
      width: 100%;
    }

    .border-button-wrapper,
    .flex-column {
      justify-content: center;
      align-items: center;

      flex-direction: column !important;
      margin: 10px !important;
    }
  }
}

@media screen and (max-width: 502px) {
  .session-card,
  .SessionCard {
    h2.title--purple {
      padding-right: 0px !important;
      font-size: 24px;
    }
  }
}

.check::before {
  background-color: #00b4e1;
  background-image: linear-gradient(90deg, #00b4e1 0%, #80d0c7 55%, #4690e9 100%);
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.check {
  background-color: #00b4e1;
  background-image: linear-gradient(90deg, #00b4e1 0%, #80d0c7 55%, #4690e9 100%);
  font-weight: bold;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: linear-gradient(90deg, #00b4e1 0%, #80d0c7 55%, #4690e9 100%);
}

.anticon-share-alt span {
  color: rgb(0, 89, 125) !important;
}
.anticon-share-alt svg path {
  color: #b587b8;
}
