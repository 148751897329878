.itc-footer {
  font-size: 1rem;
  padding: 20px;background: #00597D;
  display: flex;
  flex-direction: row;

  a,
  div,
  span {
    color: white;
  }

  .ant-layout-footer {
    font-size: 12px;
  }
  li {list-style: none;}
  a {
    &:hover {
      background-color: #00b4e1;
      // background-image: linear-gradient(90deg, #00b4e1 0%, #80d0c7 55%, #4690e9 100%);
      font-weight: bold;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.absoluteVisible {
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 9999999999;
}


.itc-footer-right-links {
  display: flex;
  justify-content: flex-end;
}
.itc-footer-right {
  padding-left: 5px;
}
.itc-footer-right-links {
  display: block;
  padding-left: 0;
  text-align: right;
}
.itc-footer-right-links li {
  display: inline-block;
}

.itc-footer-logo {
  width: 100%;
  max-width: 300px;
}

.itc-footer-right-support {
  text-align: right;
  margin-bottom: 10px;

  a {
    text-decoration: underline;
  }
}

.itc-footer-right-trademark {
  text-align: right;
}

@media screen and (max-width: 768px) {
  .itc-footer {
    font-size: 9px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .itc-footer-right ul {
    text-align: center;
  }
  .itc-footer-right-support {
    text-align: center;
  }
  .itc-footer-right-trademark {
    text-align: center;
  }
  .itc-footer-logo {
    margin-bottom: 14px;
  }
}

@media screen and (max-width: 980px) {
  .itc-footer {
    font-size: 14px;
  }
}
@media screen and (max-width: 900px) {
  .itc-footer {
    font-size: 12px;
  }
  .itc-footer-logo {
    max-width: 200px;
  }
}
@media screen and (max-width: 830px) {
  .itc-footer {
    font-size: 10px;
  }
}
