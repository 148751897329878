$blue: #00b4e1;
$lightblue: #c8f1f9;
$verylightblue: #e2f8fc;
$green: #00597d;
$darkgrey: #3f3f3f;
$darkPurple: #624b78;
$white: #ffffff;
$purpler: #463a5b;
$yellow: #fae053;
$turqoise: #00597d;

@font-face {
  font-family: 'SlatePro-Regular';
  src: url('../fonts/webFonts/SlatePro-Regular/font.woff2') format('woff2'),
    url('../fonts/webFonts/SlatePro-Regular/font.woff') format('woff');
}

@font-face {
  font-family: 'SlatePro-Bold';
  src: url('../fonts/webFonts/SlatePro-Bold/font.woff2') format('woff2'),
    url('../fonts/webFonts/SlatePro-Bold/font.woff') format('woff');
}

@font-face {
  font-family: 'SlatePro-Italic';
  src: url('../fonts/webFonts/SlatePro-Italic/font.woff2') format('woff2'),
    url('../fonts/webFonts/SlatePro-Italic/font.woff') format('woff');
}
@font-face {
  font-family: 'SlatePro-Medium';
  src: url('../fonts/webFonts/SlatePro-Medium/font.woff2') format('woff2'),
    url('../fonts/webFonts/SlatePro-Medium/font.woff') format('woff');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../fonts/webFonts/Roboto/Regular/Roboto-Regular.woff2') format('woff2'),
    url('../fonts/webFonts/Roboto/Regular/Roboto-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('../fonts/webFonts/Roboto/Bold/Roboto-Bold.woff2') format('woff2'),
    url('../fonts/webFonts/Roboto/Bold/Roboto-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Roboto-Italic';
  src: url('../fonts/webFonts/Roboto/Italic/Roboto-Italic.woff2') format('woff2'),
    url('../fonts/webFonts/Roboto/Italic/Roboto-Italic.woff') format('woff');
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('../fonts/webFonts/Roboto/Medium/Roboto-Medium.woff2') format('woff2'),
    url('../fonts/webFonts/Roboto/Medium/Roboto-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Roboto-Light';
  src: url('../fonts/webFonts/Roboto/Light/Roboto-Light.woff2') format('woff2'),
    url('../fonts/webFonts/Roboto/Light/Roboto-Light.woff') format('woff');
}

* {
  box-sizing: border-box;
  outline: none;
  font-family: 'Roboto-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.makeStyles-root-13 {
  position: relative;
  z-index: 2;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: auto;
  overflow: auto;
}

body {
  margin: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 64px;
}
html.auth body {
  padding-top: 64px !important;
}

html.game body {
  padding-top: 0 !important;
  background: none;
}
.no-padding {
  padding: 0 !important;
}

.flex {
  display: flex;
  flex: 1 1 auto;
  position: relative;
}
.flex .inline-flex {
  display: inline-flex;
}
.flex .direction-column {
  flex-direction: column;
}

.flex.wrapper {
  height: 100%;
  width: 100%;
}

.flex-column {
  flex-direction: column;
}

.vertical-center {
  align-items: center;
}

.horizontal-center {
  justify-content: center;
}

header {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  z-index: 2147483647;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  li {
    font-family: 'Roboto-Medium';
  }
}

.ant-layout-header {
  z-index: 2147483647;
}

main {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  position: relative;
  z-index: 1;
}

.ant-layout {
  min-height: 100%;
}

a.ant-typography,
.ant-typography a {
  color: #242424;
}

.ant-layout-header {
  padding: 5px 50px;
}
.image-container {
  position: relative;
  margin-bottom: 50px;
  z-index: 1;
}
.parallax-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  perspective: 1200px;
  padding-top: 15vh;
  padding-bottom: 15vh;
}
.overlay {
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  position: absolute;
}

img {
  max-width: 100%;
  transform: translateZ(0);
}
.login-row {
  width: 100%;
  height: calc(100vh - 64px - 48px);
  overflow-y: hidden;
  display: flex;
}

.login-bg .paragraph {
  color: white;
  font-size: 18px;
}

.ant-menu,
.ant-layout-header .ant-menu {
  line-height: unset !important;
  color: $green;
}
li.ant-menu-item {
  color: $green;
}
// .ant-body,
// .ant-spin-nested-loading,
// .ant-spin-container {
//   height: 100% !important;
// }

.ant-menu li,
.ant-layout-header .ant-menu li {
  color: #624b78;
  line-height: unset !important;
}
.ant-menu .ant-menu-item-selected,
.ant-layout-header .ant-menu .ant-menu-item-selected,
.ant-menu-item-active {
  color: #b587b8 !important;
}

.btn__outline--purple {
  border: 2px solid #624b78;
  border-radius: 6px;
  align-self: center;
  padding: 6px 10px;
}

.ant-menu .ant-menu-item-selected span span, .ant-layout-header .ant-menu .ant-menu-item-selected span span {
  color: #b587b8;
}

.btn__outline--green {
  border: 2px solid #04799e !important;
  border-radius: 6px;
  align-self: center;
  padding: 6px 10px;
  color: #04799e;
}

.btn__solid--purple {
  border: 2px solid #624b78;
  background-color: #624b78;
  background-image: linear-gradient(135deg, #624b78 0%, #9599e2 100%);
  border-radius: 6px;
  padding: 6px 10px;
  &:hover {
    -webkit-text-fill-color: white;
  }
}

.jumbotron__primary {
  background-size: cover !important;
  width: 100%;
  height: 500px;
}

.ant-card {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.isi {
  width: 100%;
  background: #463a5b;
}

.isi-wrapper {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.ant-layout-header ul {
  position: relative;
  top: -2px;
}

.ant-card {
  border-radius: 6px;
  border: none;
  overflow: hidden;
}
.ant-card .ant-card-extra {
  background: white;
  height: 100%;
  font-size: inherit;
  width: 100px;
  text-align: center;
}
.ant-card .ant-card-extra svg {
  fill: #00597d !important;
  font-weight: bold;
}

.ant-card-head {
  border: none;
  background: #b587b8;
  background-size: cover !important;
  color: white;
  padding-right: 0;
  border-bottom: 0;
  color: #fae053;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  min-height: 34px;
}
.ant-card-head .ant-card-head-title {
  padding-top: 0;
  padding-bottom: 0;
}
.ant-card-head .title {
  padding: 10px;
}
.ant-card-head .ant-card-extra {
  padding: 4px 0;
}
.ant-card-head .ant-card-extra a {
  color: #624b78;
}

.ant-select-dropdown {
  z-index: 2147483647 !important;
}

.gradient--green {
  background-color: #dfeaef;
}

.line {
  flex: auto;
  background: rgba(0, 0, 0, 0.3);
  height: 1px;
}

input[type='submit']:disabled {
  opacity: 0.8;
  cursor: not-allowed !important;
}

form h4,
form h4.ant-typography {
  color: #2C5773;
}
form span {
  color: #3f3f3f;
}
form input {
  height: 40px;
}

.title--purple,
.text--purple {
  color: #624b78 !important;
  font-size: 30px;
  line-height: 1.2;
}

.text--turqoise {
  color: #00597d;
}

.text__white {
  color: white !important;
}

.text__yellow {
  color: #fae053 !important;
}

.bold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

@import '~antd/dist/antd.css';

.makeStyles-root-13 {
  position: relative;
  z-index: 2;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: auto;
  background-image: url('../img/webcasts_bg.png') !important;
  background-size: cover;
}
.game {
  body,
  #root {
    background: none !important;
  }
}

// .webcasts {
//     background: url('../img/webcasts_bg.png') !important;
//     background-size: 100% !important;
//     background-repeat: no-repeat;
// }

.wrapper {
  max-width: 870px;
  margin: 0 auto;
  // background: rgba(0,0,0,.1);
  display: block;
  width: 100%;
}
.padding {
  padding: 0 20px;
}

.hide-bm-icon{
  display: none !important;
}

@media screen and (max-width: 1200px) {
  .wrapper {
    max-width: 800px;
    margin: 0 auto;
  }

   .hide-bm-icon{
    display: block !important;
  }
}

body {
  margin: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-padding {
  padding: 0 !important;
}

.flex {
  display: flex;
  flex: 1 1 auto;
  position: relative;

  .inline-flex {
    display: inline-flex;
  }
  .direction-column {
    flex-direction: column;
  }
}

.flex.wrapper {
  height: 100%;
  width: 100%;
}

.flex-column {
  flex-direction: column;
}

.vertical-center {
  align-items: center;
}

.horizontal-center {
  justify-content: center;
}
::selection {
  color: white !important;
  background: white !important;
}
header {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  z-index: 2147483647;

  ul {
    background: none !important;
  }
}

.ant-layout-header {
  z-index: 2147483647;
  background: white;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 180px;
    z-index: -1;
  }

  li,
  a {
    transition: all 0.3s ease-in-out;
    &:not(button a):hover,
    &::hover span {
      background-color: #00b4e1;
      background-image: linear-gradient(90deg, #00b4e1 0%, #80d0c7 55%, #4690e9 100%);
      font-weight: bold;
      // background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.home-page .session-card {
  max-width: 100%;
}
main {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  position: relative;
  z-index: 1;
}

.ant-layout {
  min-height: 100%;
}

a.ant-typography,
.ant-typography a {
  color: #242424;
}

.ant-layout-header {
  padding: 5px 35px;
}

.ant-menu,
.ant-layout-header .ant-menu {
  line-height: unset !important;
  // & li {
  //   color: $darkPurple;
  //   line-height: unset !important;
  // }

  .ant-menu-item-selected {
    color: #00a7b5 !important;
  }
}

.btn__outline--purple {
  border: 2px solid $darkPurple;
  border-radius: 6px;
  align-self: center;
  padding: 6px 10px;
}

.btn__solid--purple {
  border: 2px solid $darkPurple;
  background: $darkPurple;
  color: white !important;
  border-radius: 6px;
  padding: 6px 10px;
  transition: all 0.3s ease-in-out;
}

.jumbotron__primary {
  background-size: cover !important;
  width: 100%;
  height: 500px;
}

.ant-card {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ant-layout-footer {
  background: $darkPurple;
  padding: 0 !important;
}

.isi {
  width: 100%;
  background: $purpler;
}

.isi-wrapper {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.texttt {
  color: $white;
  font-family: Slate;
  max-width: 700px;
  margin: 30px 100px;
  font-size: 16px;
}

div.isi-wrapper > div.texttt > * {
  line-height: 16px;
  margin-bottom: 10px;
}

.ant-layout-header ul {
  position: relative;
  top: -2px;
}

.ant-card {
  border-radius: 6px;
  border: none;
  overflow: hidden;
  //   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,e2e2e2+100 */
  //   /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ededed+100 */
  // background: rgb(255,255,255); /* Old browsers */
  // background: -moz-linear-gradient(-45deg,  rgba(255,255,255,1) 0%, rgba(237,237,237,1) 100%); /* FF3.6-15 */
  // background: -webkit-linear-gradient(-45deg,  rgba(255,255,255,1) 0%,rgba(237,237,237,1) 100%); /* Chrome10-25,Safari5.1-6 */
  // background: linear-gradient(135deg,  rgba(255,255,255,1) 0%,rgba(237,237,237,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ededed',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  .ant-card-extra {
    background: white;
    height: 100%;
    font-size: inherit;
    width: 100px;
    text-align: center;

    svg {
      fill: $turqoise !important;
      font-weight: bold;
    }
  }

  .ant-card-body {
    background: transparent;
  }
}

.ant-card-head {
  border: none;
  background: #b587b8;
  background-size: cover !important;
  color: white;
  padding-right: 0;
  border-bottom: 0;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  min-height: 34px;

  .ant-card-head-title {
    padding-top: 0;
    padding-bottom: 0;
  }

  .title {
    padding: 10px;
  }
  .ant-card-extra {
    padding: 4px 0;
  }
  .ant-card-extra a {
    color: $darkPurple;
  }
}
.ant-select-dropdown {
  z-index: 2147483647 !important;
}
.gradient--green {
  background-color: #dfeaef;
}
.line {
  flex: auto;
  background: rgba(0, 0, 0, 0.3);
  height: 1px;
}
.ant-modal-mask {
  z-index: 99999999999999999;
}
.ant-modal-wrap {
  z-index: 99999999999999999999;
}

.ant-popover {
  z-index: 999999999999;
}

@import './buttons.scss';
@import './forms.scss';
@import './typography.scss';

.ant-modal-mask {
  z-index: 99999999999999999999;
}

.ant-modal-wrap {
  z-index: 99999999999999999999;
}

.ant-modal-confirm-btns {
  float: unset !important;
  display: flex;
  justify-content: center;
}

.ant-select-dropdown {
  z-index: 99999999999999999999;
}

.ant-message {
  z-index: 99999999999999999999;
}
.logo {
  width: auto;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 10px;
    .logo {
      width: 150px;
      height: auto;
    }
  }

  .r-col {
    margin: 0 auto;
  }
  .interest-topics {
    background-size: cover !important;

    .column-wrap {
      padding-left: 20px;
    }
    .column-wrap,
    .flex .ant-row {
      flex-direction: column;
      width: 100%;
      flex-flow: column;

      .ant-col-8 {
        &,
        & button {
          flex-basis: 100%;
          max-width: 100% !important;
        }
      }
    }
  }
}

.loader {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  background: linear-gradient(
    0.9turn,
    $lightblue,
    $darkPurple,
    lighten($lightblue, 50%),
    $darkPurple
  );
  z-index: 999999999999999999999999999999999999999999999999999999;
  background-size: 400% 400%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .loader-logo {
    width: 100%;
    max-width: 300px;
    position: absolute;
    align-self: center;
    z-index: 99999999999;
  }
}

.ant-select-selector {
  min-height: 40px !important;
  padding: 5px 12px !important;
}

.hooks-main > svg {
  display: none;
}

.hooks-main > div {
  position: absolute;
  will-change: transform;
  border-radius: 50%;
  background: #624b78;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  opacity: 0.6;
}

.hooks-main > div:nth-child(1) {
  width: 120px;
  height: 120px;
}

.hooks-main > div:nth-child(2) {
  width: 250px;
  height: 250px;
}

.hooks-main > div:nth-child(3) {
  width: 150px;
  height: 150px;
}

.hooks-main > div::after {
  content: '';
  position: absolute;
  top: 20px;
  left: 20px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: rgba(0, 167, 181, 0.8);
}

.hooks-main > div:nth-child(2)::after {
  top: 70px;
  left: 70px;
  width: 70px;
  height: 70px;
}

.hooks-main > div:nth-child(3)::after {
  top: 50px;
  left: 50px;
  width: 50px;
  height: 50px;
}

.hooks-main {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: url('#goo');
  overflow: hidden;
}

.ant-modal-body {
  button {
    width: 100%;
    max-width: 350px;
  }
}

@media screen and (max-width: 600px) {
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    margin: 0;
    margin-left: 20px;
  }
}

@media screen and (max-width: 600px) {
  #register_form #user_lookup_form {
    .flex,
    .ant-row.ant-form-item {
      max-width: 100% !important;
      width: 100% !important;
      display: block;
    }
  }
}

// ie bug fix
div.ant-row.ant-form-item > div.ant-col.ant-col-24.ant-form-item-control {
  margin-bottom: 20px;
}

// ie bug fix
div.ant-col.ant-col-24.ant-form-item-control > div.ant-form-item-control-input {
  margin-bottom: 10px;
}
.ant-modal-body button {
  max-width: 100% !important;
}

::selection {
  background-color: #624b78 !important;
}


.ant-select-item-option-content{
  white-space: normal;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(155, 155, 155, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(155, 155, 155, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(155, 155, 155, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(155, 155, 155, 0.4);
    box-shadow: 0 0 0 0 rgba(155, 155, 155, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 30px rgba(155, 155, 155, 0);
    box-shadow: 0 0 0 30px rgba(155, 155, 155, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(155, 155, 155, 0);
    box-shadow: 0 0 0 0 rgba(155, 155, 155, 0);
  }
}

.glow-box,
.btn-dark,
.score-wrapper .btn-medium {
  cursor: pointer;
  animation: pulse 0.8s infinite;
  border: 1px solid transparent;
}

.btn-dark:disabled,
.score-wrapper .btn-medium:disabled {
  animation: none;
}

.glow-box:hover,
.btn-dark:hover,
.btn-medium:hover {
  cursor: pointer;
}

.isi-backup {
  z-index: 99999999999999999999999999999999999999999999999999999999 !important;
}

@media screen and (max-width: 768px) {
  form .flex {
    flex-direction: column !important;
    input {
      width: 100%;
    }
  }
  form,
  .ant-row.ant-form-item {
    width: 100% !important;
  }
}

@media (min-width: 992px) {
  .ant-col-lg-6 {
    display: block;
    -ms-flex: 0 0 25%;
    flex: 0 0 400px;
    max-width: 400px !important;
    flex: none;
  }
}

.interest-topics-bg {
  background: url('../img/My_interests_background.png');
  background-size: cover !important;
  background-position: center;
}

:required {
  background: #e5f6f8;
}

.ant-input-disabled {
  background-color: #e5f6f8;
}

li.ant-menu-item {
  font-size: 12px;
  margin: 0 10px !important;
}

.ant-menu li,
.ant-layout-header .ant-menu li,
.ant-menu li,
.ant-layout-header .ant-menu li {
  &:visited {
    color: #00597d;
  }
  color: #00597d !important;

  &:hover {
    color: #b587b8 !important;
  }
}

.ant-menu .ant-menu-item-selected,
.ant-layout-header .ant-menu .ant-menu-item-selected {
  color: #b587b8 !important;
}

.footer-styles {
  margin-right: 6px;
}

.profile-reg-form label {
  font-size: 16px !important;
  font-weight: 100 !important;
  font-family: 'Roboto-Light';
}

li.ant-menu-item {
  margin: 0 !important;
  // padding: 0 !important;
}
// .ant-checkbox .ant-checkbox-inner {
//   background-image: url('../img/unchecked.png') !important;
//   background-color: transparent !important;
//   background-size: cover !important;
//   height: 16px;
//   width: 16px;
//   display: inline-block;
//   padding: 0 0 0 0px;
// }

// .ant-checkbox-checked .ant-checkbox-inner {
//   background-image: url('../img/checked.png');
//   background-color: transparent !important;
//   background-size: cover !important;
//   height: 16px;
//   width: 16px;
//   display: inline-block;
//   padding: 0 0 0 0px;
// }
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  display: none !important;
}


.contactrep-topic-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgb(0, 89, 125);
  border-color: rgb(0, 89, 125);
  width: 20px;
  height: 20px;
}

.contactrep-topic-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FFFFFF;
  border-color: #B3B3B2;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #B3B3B2;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 0px;
  color: #000000 !important;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

#contactrep label.ant-form-item-required {
  color: rgb(0, 89, 125) !important;
}

#contactrep .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 0px;
  color: #000000 !important;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '';
}

#register_form .ant-form-item-extra{
  color: rgba(0, 0, 0, 0.65);
  font-family: 'Roboto-Light';
}

.ant-card .ant-card-extra svg {
    fill: #AB8DB3 !important;
    font-weight: bold;
}

.ant-card-head .ant-card-extra {
    padding: 5px 0;
}

.itcInputsCheckbox {
  .ant-checkbox {
    top: 0;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #FFFFFF;
    }
  }

  .ant-checkbox-inner {
    border-color: #B3B3B2;
    background-color: #04799e;
    border: 1px solid #FFFFFF;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #AC89B5;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }
}

button.share-submit-btn {
  padding: 5px 20px !important;
  border: 1px solid #A3A0AE !important;
  color: #615571 !important;
  background-color: transparent !important;
  border-radius: 0px !important;
  cursor: pointer;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  z-index: 999999;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  height: auto !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  text-align: center;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin-bottom: 15px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-active{
  color: $darkPurple;
}

@media screen and (max-width: 768px) {
  .mobileVertical {
    flex-direction: column;
  }
}