$blue: #00b4e1;
$lightblue: #c8f1f9;
$verylightblue: #e2f8fc;
$green: #9dbf2a;
$darkgrey: #3f3f3f;
$grey: #e3e3e3;
$darkPurple: #624b78;
$white: #ffffff;
$purpler: #463a5b;
$yellow: #fae053;
$turqoise: #00a7b5;
