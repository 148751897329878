.header-active-underline-hr {
  display: none;
}

.header-active-underline.ant-menu-item-selected .header-active-underline-span {
  position: absolute;
  background-color: #00597d;
  height: 2px;
  bottom: -6px;
  left: 15%;
  width: 70%;
}

.header-item span {
  font-family: 'Roboto-Bold';
}

.share-modal {
  position: relative;
}

.share-modal .ant-modal-content .ant-modal-close {
  position: absolute;
  top: -50px;
  right: -20px;
}

.share-modal .ant-modal-content .ant-modal-close .ant-modal-close-x .ant-modal-close-icon svg {
  font-size: 24px;
}

.share-modal .ant-modal-content .ant-modal-close .ant-modal-close-x .ant-modal-close-icon svg path {
  color: #ffffff !important;
}

.ant-select-multiple .ant-select-selection-search {
  margin-inline-start: 0;
}

@media screen and (max-width: 600px) {
  .header-item {
    padding: 0 5px !important;
  }

  .ant-input,
  .ant-select {
    font-size: 16px;
  }
}

.purple-btn {
  background-color: #875799;
}

.purple-btn button {
  background-color: #875799;
}

.new_indications_h2_bg_title {
  position: relative;
  z-index: 0;
}

.new_indications_h2_bg {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: -185px;
  z-index: -1;
  background-image: linear-gradient(to right, transparent, #b18bb6, transparent);
}

@media screen and (max-width: 1020px) {
  .new_indications_h2_bg {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
    z-index: -1;
    background-image: linear-gradient(to right, transparent, #b18bb6, transparent);
  }
}

.game_banner_parent {
  position: relative;
  top: 25px;
  left: 0;
  max-width: 900px;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  /* margin-bottom: 50px; */
}
.game_banner_bg {
  position: relative;
  top: 0;
  left: 0;
}
.register_banner_parent {
  position: relative;
  top: 0;
  left: 0;
  max-width: 900px;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  margin-bottom: 50px;
}
.register_banner_bg {
  position: relative;
  top: 0;
  left: 0;
}
.register_banner_content {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 50px;
}
.register_banner_profile_img {
  max-width: 130px;
  border-radius: 50%;
  margin-right: 10px;
}
.register_banner_content_text {
  color: white;
  font-size: 24px;
  font-weight: 900;
  text-align: left;
  line-height: 1.2;
}
.register_banner_button {
  max-width: 200px;
  margin-top: 10px;
}

@media screen and (max-width: 940px) {
  .register_banner_parent,
  .game_banner_parent {
    max-width: 95%;
  }
  .register_banner_content {
    padding: 0 20px;
  }
  .register_banner_profile_img {
    max-width: 120px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .register_banner_content_text {
    font-size: 20px;
  }
  .register_banner_button {
    max-width: 180px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 800px) {
  .register_banner_profile_img {
    max-width: 100px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .register_banner_content_text {
    font-size: 18px;
  }
  .register_banner_button {
    max-width: 180px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 769px) {
  .register_banner_parent,
  .game_banner_parent {
    max-width: 100%;
  }
}

@media screen and (max-width: 715px) {
  .register_banner_content_text {
    font-size: 14px;
  }
  .register_banner_button {
    max-width: 150px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 545px) {
  .register_banner_profile_img {
    max-width: 80px;
  }
  .register_banner_content_text {
    font-size: 12px;
  }
  .register_banner_button {
    max-width: 120px;
    margin-top: 10px;
  }
}

.ant-avatar > img {
  object-position: top;
}
